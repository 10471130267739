<template>
    <div class="all_main_wrap">
        <HeaderBox>
            <template #title> 活动管理 </template>
            <template #input>
                <a-input placeholder="请输入活动名称" v-model="queryParams.name" allowClear />
                <a-select placeholder="请选择活动类型" v-model="queryParams.activityType" showSearch allowClear>
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option :value="0">免费</a-select-option>
                    <a-select-option :value="1">付费</a-select-option>
                </a-select>
                <a-select placeholder="请选择活动状态" v-model="queryParams.published" showSearch allowClear>
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option :value="1">上架</a-select-option>
                    <a-select-option :value="0">下架</a-select-option>
                </a-select>
                <a-range-picker :placeholder="['活动开始时间', '活动结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectTime" v-model="times"></a-range-picker>
                <a-button type="primary" class="btn" :loading="loading" @click="onSearch()">搜索</a-button>
                <a-button class="all_boder_btn" style="margin-right: 20px" @click="onEmpty()">重置</a-button>
                <a-button class="all_boder_btn" style="margin-right: 20px" @click="goDetail()">新建</a-button>
            </template>
        </HeaderBox>
        <!-- 表格列表 -->
        <div class="table-box">
            <a-table class="table-template" :rowKey="item => item.id"  :columns="columns" :data-source="tableData"
                @change="onPage" :loading="loading" :pagination="{
                    total: total,
                    current: queryParams.pageNum,
                    defaultPageSize: queryParams.pageSize,
                    showSizeChanger: true,
                    showTotal: function (total, range) {
                        return `共${total}条`;
                    },
                }">
                <template slot="index" slot-scope="item, row, i">
                    {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
                </template>
                <!-- 活动类型 -->
                <template slot="activityType" slot-scope="item">
                    {{ item == 1 ? '付费' : '免费'}}
                </template>
                <!-- 活动时间 -->
                <template slot="activeTime" slot-scope="item">
                    {{ item.activityStartDate }} ~ {{ item.activityEndDate }}
                </template>
                <!-- 是否上下架 -->
                <template slot="isFlag" slot-scope="item">
                    <a-switch @change="onChangeFlag(item)" :checked="convertToBoolean(item.published)" />
                </template>
                <!-- 上下架状态 -->
                <template slot="published" slot-scope="item">
                    <a-tag :color="item == 1 ? 'green' : 'red'">{{ item == 1 ? '上架' : '下架'}}</a-tag>
                </template>
                <template slot="sort" slot-scope="item,row">
                    <a-input-number id="inputNumber" v-model="row.sort" :min="1" @change="onSortChange(row)" />
                </template>
               
                <template slot="operation" slot-scope="item">
                    <div class="blueText">
                        <span @click="goDetail(item.id)">编辑</span>
                        <span> | </span>
                        <a-popconfirm title="确认删除该活动吗？" @confirm="onActivityDel(item.id)" ok-text="是" cancel-text="否">
                            <a href="#" >删除</a>
                        </a-popconfirm>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
const columns = [
    {
        title: "序号",
        width: "80px",
        align: "center",
        dataIndex: "index",
        scopedSlots: { customRender: "index" },
    },
    {
        title: "活动名称",
        align: "center",
        width: 300,
        dataIndex: "name",
    },
    {
        title: "活动类型",
        align: "center",
        dataIndex: "activityType",
        scopedSlots: { customRender: "activityType" },
    },
    {
        title: "活动时间",
        align: "center",
        scopedSlots: { customRender: "activeTime" },
    },
    {
        title: "举办城市",
        align: "center",
        dataIndex: "city",
    },
    {
        title: "上下架",
        align: "center",
        scopedSlots: { customRender: "isFlag" },
    },
    {
        title: "活动状态",
        align: "center",
        dataIndex: "published",
        scopedSlots: { customRender: "published" },
    },
    {
        title: "排序",
        align: "center",
        dataIndex: "sort",
        scopedSlots: { customRender: "sort" },
    },
    {
        title: "操作",
        align: "center",
        scopedSlots: { customRender: "operation" },
    },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
    // 可用组件的哈希表
    components: { HeaderBox },
    // 接收传值
    props: {},
    // 数据对象
    data() {
        return {
            // 筛选条件
            queryParams: {
                name: undefined, // 活动名称
                activityType: undefined, // 活动类型 0免费 1付费
                published: undefined, // 活动状态 0下架 1上架
                activityStartDate: undefined, // 活动开始时间
                activityEndDate: undefined, // 活动结束时间	
                pageNum: 1,
                pageSize: 10,
            },
            times:[],
            columns, // 表头
            tableData: [], // 列表数据
            loading: false, // 列表加载
            total: 0, // 总数量
        };
    },
    // 事件处理器
    methods: {
        convertToBoolean(value){
            return value === 1;
        },
        // 分页
        onPage(e) {
            this.queryParams.pageNum = e.current;
            this.queryParams.pageSize = e.pageSize;
            this.getList();
        },
        // 选择时间
        onSelectTime(date, dateString) {
            this.queryParams.activityStartDate = dateString[0]; // 开始日期
            this.queryParams.activityEndDate = dateString[1]; // 结束日期
        },
        // 重置
        onEmpty() {
            this.queryParams = {
                name: undefined, // 活动名称
                activityType: undefined, // 活动类型 0免费 1付费
                published: undefined, // 活动状态 0下架 1上架
                activityStartDate: undefined, // 活动开始时间
                activityEndDate: undefined, // 活动结束时间	
                pageNum: 1,
                pageSize: 10,
            },
            this.times = []
            this.getList();
        },
        // 搜索
        onSearch() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        // 数据-----------------
        onActivityDel(id){
            this.$ajax({
                url: "/hxclass-management/activity/manage/delete?id=" + id,
                method: "DELETE",
            }).then((res) => {
                if (res.code == 200 && res.success) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.warning(res.message);
                }
            });
        },
        // 控制课程预览
        onChangeFlag(e){
            let _this = this;
            _this.$confirm({
                title: "确定" + (e.published ? '下架':'上架') + "该活动吗?",
                okText: "确认",
                onOk() {
                    _this.loading = true;
                    _this.$ajax({
                        url: "/hxclass-management/activity/manage/audit",
                        method: "POST",
                        params: {
                            id: e.id,
                            published: e.published ? 0:1,
                        },
                    }).then((res) => {
                        if (res.code == 200 && res.success) {
                            _this.$message.success(res.message);
                            _this.getList();
                        } else {
                            _this.$message.warning(res.message);
                        }
                        _this.loading = false;
                    });
                },
                onCancel() {},
            });
        },
        // 修改位置
        onSortChange(item) {
            let params = {
                id: item.id,
                sort: item.sort,
            }
            if(this.timer) {
                clearTimeout(this.timer)
            }
            let _this = this;
            this.timer = setTimeout(()=>{
                this.timer = null;
                _this
                .$ajax({
                    url: "/hxclass-management/activity/manage/audit",
                    method: "post",
                    params: params
                })
                .then((res) => {
                    if (res.code == 200 && res.success) {
                        _this.$message.success("操作成功");
                        _this.getList();
                    }
                });
            },600)
        },
        // 列表
        getList() {
            this.loading = true
            this.$ajax({
                url: "/hxclass-management/activity/manage/list",
                params: this.queryParams,
            }).then((res) => {
                this.loading = false
                if (res.code == 200 && res.success) {
                    this.total = res.data.total;
                    this.tableData = res.data.records;
                }
            });
        },

        // 跳转----------------
        // 详情、修改
        goDetail(id) {
            this.$router.push({ path: "/admin/Activity/ActivityDetail", query: { acticityId: id } });

        },
    },
    // 生命周期-实例创建完成后调用
    created() {
        this.getList();
    },
    // 生命周期-实例挂载后调用
    mounted() { },
    // 生命周期-实例销毁离开后调用
    destroyed() { },
    // 计算属性监听
    computed: {
        rowSelection() {
            return {
                type: "radio",
                onChange: this.select,
            };
        },
    },
    // 自定义的侦听器
    watch: {},
};
</script>

<style lang="less" scoped>
.time {
    display: inline-block;

    /deep/ .ant-select {
        margin: 0 0px 14px 0 !important;
    }

    /deep/.ant-select-selection--single {
        height: 32px !important;
    }

    /deep/.ant-calendar-picker {
        width: 380px !important;
    }
}

.btn {
    margin-right: 24px;
}

.table-box {
    /deep/ .ant-tabs-bar {
        border-bottom: none;
    }
}

/deep/.right-box {
    padding-bottom: 5px !important;
}

.modalDiv {
    >p {
        font-weight: 500;
        color: #333333;
    }

    .code-item {
        >span {
            display: inline-block;
            min-width: 100px;
            margin: 5px 0;
            margin-right: 15px;
        }
    }
}

/deep/.ant-modal-body {
    overflow: hidden;
}

/deep/.ant-modal-title {
    text-align: center;
    color: #000000;
}

/deep/.ant-modal-header {
    border-bottom: none;
}

/deep/.ant-modal-footer {
    text-align: center;
    border-top: none;
}

/deep/ .ant-modal-body {
    padding: 14px 36px 0px;
}

.table-template {
    /deep/.ant-table {
        border: none;
    }
}

.search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .search-input {
        width: 36%;
    }
}
</style>